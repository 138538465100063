<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/limitedDaysWithoutChargeService'
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    const getMeta = async () => {
      const meta = await service.getMeta()

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        helpers.createBaseLocalityValidations(validationSchema, translate)

        return yup.object().shape(validationSchema)
      }
      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
